import Theme from 'api/theme'

import constants from 'constants/theme'

export const fetchTheme = () => async (dispatch) => {
  dispatch({
    type: constants.FETCH_THEME_REQUEST
  })

  try {
    const response = await Theme.fetchAll()

    dispatch({
      type: constants.FETCH_THEME_SUCCESS,
      theme: { id: response.id, ...response.attributes() }
    })
  } catch (error) {
    dispatch({
      type: constants.FETCH_THEME_FAILURE,
      errorMessage: error.statusText || error.status
    })
  }
}

export const updateTheme = (key, value) => {
  return {
    type: constants.UPDATE_THEME,
    key,
    value
  }
}

export const saveTheme = () => async (dispatch, getState) => {
  dispatch({
    type: constants.SAVE_THEME_REQUEST
  })

  const theme = new Theme(getState().theme.theme)

  try {
    await theme.save()

    dispatch({
      type: constants.SAVE_THEME_SUCCESS
    })
  } catch (response) {
    dispatch({
      type: constants.SAVE_THEME_FAILURE,
      errorMessage: response.message || response.statusText || response.status,
      errors: theme.errors.extract()
    })
  }
}
