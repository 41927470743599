import constants from '../constants/project-tag'
import ProjectTag from 'api/project-tag'

export const fetchProjectTags = () => async (dispatch) => {
  dispatch({
    type: constants.FETCH_PROJECT_TAGS_REQUEST
  })
  try {
    const tags = await ProjectTag.query({ include_unused: 1 })
    dispatch({
      type: constants.FETCH_PROJECT_TAGS_SUCCESS,
      tags
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_PROJECT_TAGS_FAILURE,
      errorMessage: response.message || response.statusText || response.status
    })
  }
}
