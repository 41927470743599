import isProduction from 'lib/is-production';

function getImgixUrl(imageUrl: string) {
  const lastIndex = imageUrl.lastIndexOf('//');
  let imagePath = imageUrl.slice(lastIndex);
  imagePath = imageUrl.split('//')[1];

  imagePath = imagePath.replace('s3.amazonaws.com', '');
  imagePath = /[0-9a-z]+\/(.*)/gi.exec(imagePath)[1];

  // fetch region
  const region = imagePath.split('/')[0].split('-')[2];

  const preProd = process.env.NEXT_PUBLIC_IMGIX_PRE_PROD_URL;
  const staging = process.env.NEXT_PUBLIC_IMGIX_NEW_STAGING_URL;

  if (staging) {
    imagePath = imagePath.replace('ehq-staging/', '');

    return `https://${process.env.NEXT_PUBLIC_IMGIX_NEW_STAGING_URL}/${imagePath}`;
  }

  // if development
  if (!isProduction) {
    return imageUrl;
  }

  // if pre production
  if (preProd) {
    if (imagePath.includes('ehq-replica-australia/')) {
      imagePath = imagePath.replace('ehq-replica-australia/', '');
    }

    return `https://${process.env.NEXT_PUBLIC_IMGIX_PRE_PROD_URL}/${imagePath}`;
  }

  const imgixRegion = getRegion(region, imagePath, imageUrl);

  return `https://${imgixRegion}`;
}

function getRegion(region: string, imagePath: string, originalImage: string) {
  let australiaImage: string;
  let canadaImage: string;
  let europeImage: string;
  let euImage: string;
  let californiaImage: string;

  switch (region?.toUpperCase()) {
    case 'AUSTRALIA':
      australiaImage = imagePath;

      if (australiaImage.includes('ehq-production-australia/')) {
        australiaImage = australiaImage.replace(
          'ehq-production-australia/',
          '',
        );
      }

      australiaImage = `${process.env.NEXT_PUBLIC_IMGIX_AUSTRALIA_URL}/${australiaImage}`;

      return australiaImage;

    case 'CANADA':
      canadaImage = imagePath;

      if (canadaImage.includes('ehq-production-canada/')) {
        canadaImage = canadaImage.replace('ehq-production-canada/', '');
      }

      canadaImage = `${process.env.NEXT_PUBLIC_IMGIX_CANADA_URL}/${canadaImage}`;

      return canadaImage;

    case 'EUROPE':
      europeImage = imagePath;

      if (europeImage.includes('ehq-production-europe/')) {
        europeImage = europeImage.replace('ehq-production-europe/', '');
      }

      europeImage = `${process.env.NEXT_PUBLIC_IMGIX_EUROPE_URL}/${europeImage}`;

      return europeImage;

    case 'NEW':
      euImage = imagePath;

      if (euImage.includes('ehq-production-new-eu/')) {
        euImage = euImage.replace('ehq-production-new-eu/', '');
      }

      euImage = `${process.env.NEXT_PUBLIC_IMGIX_NEW_EUROPE_URL}/${euImage}`;

      return euImage;

    case 'US':
      californiaImage = imagePath;

      if (californiaImage.includes('ehq-production-us-california/')) {
        californiaImage = californiaImage.replace(
          'ehq-production-us-california/',
          '',
        );
      }

      californiaImage = `${process.env.NEXT_PUBLIC_IMGIX_CALIFORNIA_URL}/${californiaImage}`;

      return californiaImage;

    default:
      return originalImage;
  }
}

export function buildImgixUrl(
  image: string,
  height = 1500,
  width = 1500,
  maxWidth = 2500,
  minWidth = 1500,
) {
  if (!image) {
    return image;
  }

  if (image.includes('amazonaws')) {
    const imgixUrl = getImgixUrl(image);
    const imgixUrlWithDimension = `${imgixUrl}?auto=format&auto=compress&h=${height}&w=${width}&max-w=${maxWidth}&min-w=${minWidth}`;

    return imgixUrlWithDimension;
  }

  if (image.includes('imgix')) {
    const imgixUrlWithDimension = `${image}?auto=format&auto=compress&h=${height}&w=${width}&max-w=${maxWidth}&min-w=${minWidth}`;

    return imgixUrlWithDimension;
  }

  return image;
}
