export function addCSPNonceToHtml(html: string, nonce: string): string {
  if (!html) return html;

  // Regular expressions to match the required tags
  const scriptTagRegex = /<script\s+([^>]*?)>/g;
  const linkTagRegex = /<link\s+([^>]*?)>/g;
  const styleTagRegex = /<style\s+([^>]*?)>/g;

  // Helper function to add nonce to a tag if it is missing
  const addNonceToTag = (tag: string, nonce: string): string => {
    // Check if the nonce is already present
    if (tag.includes('nonce="')) {
      return tag;
    }
    // Add nonce attribute to the tag
    return tag.replace(/<(\w+)([^>]*)>/, (_, tagName, attributes) => {
      return `<${tagName} ${attributes} nonce="${nonce}">`;
    });
  };

  // Add nonce to all <script> tags
  html = html.replace(scriptTagRegex, (match) => addNonceToTag(match, nonce));

  // Add nonce to all <link> tags (typically for stylesheets)
  html = html.replace(linkTagRegex, (match) => addNonceToTag(match, nonce));

  // Add nonce to all <style> tags
  html = html.replace(styleTagRegex, (match) => addNonceToTag(match, nonce));

  return html;
}
