import constants from 'constants/layout'
import Layout from 'api/layout'

export const fetchLayout = () => async (dispatch) => {
  dispatch({
    type: constants.FETCH_LAYOUT_REQUEST
  })

  try {
    const layout = await Layout.fetch('selected')

    dispatch({
      type: constants.FETCH_LAYOUT_SUCCESS,
      layout
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_LAYOUT_FAILURE,
      errorMessage: response.statusText || response.status
    })
  }
}

export const updateLayout = (key, value) => {
  return {
    type: constants.UPDATE_LAYOUT,
    key,
    value
  }
}

export const saveLayout = () => async (dispatch, getState) => {
  dispatch({
    type: constants.SAVE_LAYOUT_REQUEST
  })

  const layout = new Layout(getState().layout.layout)

  try {
    await layout.save()

    dispatch({
      type: constants.SAVE_LAYOUT_SUCCESS
    })
  } catch (error) {
    dispatch({
      type: constants.SAVE_LAYOUT_FAILURE,
      errorMessage: error.message || error.statusText || error.status,
      errors: layout.errors.extract()
    })
  }
}

export const resetLayout = () => async (dispatch, getState) => {
  dispatch({
    type: constants.RESET_LAYOUT_REQUEST
  })

  const layout = new Layout(getState().layout.layout)

  try {
    const response = await layout.reset()

    dispatch({
      type: constants.RESET_LAYOUT_SUCCESS,
      layout: response
    })
  } catch (error) {
    dispatch({
      type: constants.RESET_LAYOUT_FAILURE,
      errorMessage: error.statusText || error.status
    })
  }
}

export const resetLayoutColor = () => async (dispatch, getState) => {
  dispatch({
    type: constants.RESET_LAYOUT_COLOR_REQUEST
  })

  const layout = new Layout(getState().layout.layout)

  try {
    const response = await layout.resetColor()

    dispatch({
      type: constants.RESET_LAYOUT_COLOR_SUCCESS,
      layout: response
    })
  } catch (error) {
    dispatch({
      type: constants.RESET_LAYOUT_COLOR_FAILURE,
      errorMessage: error.statusText || error.status
    })
  }
}
